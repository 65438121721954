.hero-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Ensures the hero section covers the entire page height */
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  position: relative;
  margin-bottom: -1rem;
}

.hero-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.hero-label {
  color: #8522B9;
  width: 60%;
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 1.2;
  /* Ensures the text fits within two lines */
  margin-bottom: 1rem;
}

@media only screen and (max-width: 750px) {
  .hero-label {
    width: 80%;
    font-size: 2rem;
  }
}

.hero-subLabel {
  width: 60%;
  font-size: 1.35rem;
  line-height: 1.4;
  /* Ensures the text fits within two lines */
  margin-bottom: 3rem;
  color: white;
  font-weight: 400;
}

@media only screen and (max-width: 750px) {
  .hero-subLabel {
    width: 80%;
    font-size: 1.25rem;
  }
}

/* Carousel dots positioned lower */
.carousel-dots {
  position: absolute;
  bottom: 2rem;
  display: flex;
  justify-content: center;
  width: 100%;
}