.footer-container {
  background: #020515;
  color: white;
  padding: 2rem 15rem;
  height: auto;
}

.footer-logo {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  align-items: center;
  gap: 0.5rem;
}

.footer-logo-img {
  width: 4rem;
}

.footer-logo-text {
  font-size: 1.7rem;
  color: #8522B9;
  letter-spacing: 8px;
  font-weight: 500;
}

.footer-divider {
  height: 1px;
  background-color: gray;
  border: none;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.footer-section {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.footer-section1{
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.footer-item {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.footer-icon {
  width: 1rem;
}

.footer-text {
  font-size: 0.7rem;
  opacity: 80%;
  font-weight: normal;
}

.newsletter-container {
  background: #03071C;
  width: 209px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.8rem;
  border-radius: 10px;
  height: max-content;
}

.newsletter-label {
  font-weight: 400;
  font-size: 0.9rem;
}

.newsletter-input {
  background: #0B0F26;
  outline: none;
  border-radius: 4px;
  color: #616161;
  padding: 0.5rem;
  font-size: 0.5rem;
  border: none;
}

.newsletter-button {
  background: #885EFE;
  width: 5rem;
  cursor: pointer;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: white;
  border: none;
  font-size: 0.5rem;
  padding: 0.5rem;
}

.newsletter-disclaimer {
  opacity: 50%;
  font-size: 0.7rem;
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .footer-container {
      padding: 2rem 1rem;
      height: auto;
  }

  .footer-content {
      flex-direction: column;
      gap: 1rem;
  }

  .footer-section {
      /* align-items: left;
      text-align: center; */
  }

  .footer-item {
      justify-content: left;
  }

  .newsletter-container {
      width: 90%;
      text-align: center;
  }

  .footer-logo-text {
      font-size: 1rem;
      color: #8522B9;
      letter-spacing: 4px;
      font-weight: 300;
  }
  .footer-item-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.footer-item-container{
  display: flex;
  justify-content: space-between;
  gap: 4rem;
  /* align-items: center; */
}
