.about-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  margin-top: -1.25rem;
  padding: 2rem;
  box-sizing: border-box;
}

.about-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  /* Space between hrLine and title */
  margin-bottom: 1rem;
  width: 100%;
  text-align: center;
}

.about-title {
  font-size: 3rem;
  color: #8522B9;
  font-weight: bold;
  white-space: nowrap;
  /* Prevent text from wrapping */
}

.about-hrLine {
  width: 20rem;
  /* Ensure it matches the width in the Meet Our Founders section */
  max-width: none;
  flex-grow: 1;
  opacity: 1;
}

.about-title2 {
  display: none;
}

.about-content {
  display: flex;
  gap: 2rem;
  text-align: justify;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
  padding: 2rem 0;
}

.about-logo {
  width: 14rem;
  max-width: 100%;
  height: auto;
  transform: translate(80px, -40px);
}

.about-text-content {
  width: 60%;
  max-width: 800px;
}

.about-text {
  font-size: 1.45rem;
}

/* Media Queries for Responsiveness */

@media only screen and (max-width: 1200px) {
  .about-hrLine {
    width: 20rem;
  }

  .about-title {
    font-size: 2.5rem;
    /* Reduce font size slightly to fit on smaller screens */
  }
}

@media only screen and (max-width: 900px) {
  .about-container {
    margin-top: -3rem;
  }

  .about-hrLine {
    display: none;
  }

  .about-content {
    flex-direction: column;
    align-items: center;
  }

  .about-title {
    font-size: 2rem;
    /* Further reduce font size on smaller screens */
  }

  .about-text-content {
    width: 90%;
  }
}

@media only screen and (max-width: 768px) {
  .about-container {
    padding: 1rem;
    margin-top: -3rem;
    /* Reduced gap for phones */
  }

  .about-title {
    font-size: 2.5rem;
  }

  .about-logo {
    width: 10rem;
    transform: none;
    margin-bottom: 1rem;
  }

  .about-text {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 480px) {
  .about-container {
    margin-top: -3.5rem;
    /* Further reduced gap for smaller phones */
  }

  .about-text-content {
    margin-top: -2.5rem;
  }

  .about-title {
    font-size: 2rem;
  }

  .about-logo {
    width: 8rem;
  }

  .about-text {
    font-size: 1rem;
  }
}