/* Ensure the carousel wrapper fills the entire space and has room for dots */
.carousel-wrapper {
    width: 100%;
    position: relative;
    padding-bottom: 40px;
    /* Add padding to accommodate the dots */
}

/* Adjust the position of the carousel dots */
.carousel-wrapper .carousel .control-dots {
    position: absolute;
    bottom: -14px;
    /* Keep at 0 or adjust slightly */
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
}

/* Optional: Customize the size and color of the dots */
.carousel-wrapper .carousel .control-dots .dot {
    width: 12px;
    /* Adjust dot size */
    height: 12px;
    /* Adjust dot size */
    background-color: #8522B9;
    /* Dot color */
}

/* Optional: Style the active dot */
.carousel-wrapper .carousel .control-dots .dot.selected {
    background-color: #A5A5A5;
    /* Active dot color */
}