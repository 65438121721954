/* Navbar Container */
.navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 7rem;
  position: relative;
}

/* Navbar Logo */
.navbar-logo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.navbar-logo-img {
  width: 3rem;
}

.navbar-logo-text {
  font-size: 0.9rem;
  color: #8522B9;
  letter-spacing: 3px;
  font-weight: 500;
}

/* Navbar Items */
.navbar-items {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.navbar-item {
  display: flex;
  min-width: 7rem;
  align-items: center;
  cursor: pointer;
  gap: 0.5rem;
}

.navbar-icon {
  width: 2rem;
}

.navbar-icon-container {
  width: 2rem;
}

.navbar-label {
  font-weight: 600;
  cursor: pointer;
  font-size: 0.7rem;
}

/* Hamburger Menu */
.hamburger-menu {
  display: none;
  flex-direction: column;
  gap: 0.2rem;
  cursor: pointer;
}

.hamburger-bar {
  width: 2rem;
  height: 0.2rem;
  background: #8522B9;
}

/* Mobile Menu */
.mobile-menu {
  display: none;
  position: absolute;
  top: 100%;
  right: 1rem;
  background: white;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  border-radius: 0.5rem;
  padding: 1rem;
  width: 200px;
  z-index: 1000;
}

.mobile-menu.show {
  display: block;
}

.mobile-menu-item {
  padding: 0.5rem;
  cursor: pointer;
  font-size: 1rem;
  color: #8522B9;
}

.mobile-menu-item:hover {
  background: #f5f5f5;
}

/* Responsive Styles */
@media only screen and (max-width: 1200px) {
  .navbar-container {
    padding: 1.5rem 3rem;
  }
  
  .navbar-items {
    gap: 1.5rem;
  }
}

@media only screen and (max-width: 900px) {
  .navbar-container {
    padding: 1.5rem 1.5rem;
  }
  
  .navbar-items {
    display: none;
  }

  .hamburger-menu {
    display: flex;
  }
}

@media only screen and (max-width: 600px) {
  .navbar-logo-text {
    font-size: 0.7rem;
  }

  .navbar-icon {
    width: 1.5rem;
  }

  .navbar-label {
    font-size: 0.6rem;
  }
}
