/* General Container */
.contact-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
  font-family: Calibri, 'Trebuchet MS', sans-serif;
}

/* Header Section */
.contact-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 1rem;
}

.contact-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 80rem;
  margin: 0 auto;
}

.contact-title {
  font-size: 2.5rem !important;
  color: #8522B9;
  font-weight: bold;
  white-space: nowrap;
  margin: 0 1rem;
}

.contact-hrLine {
  width: 40rem;
  max-width: 40%;
  opacity: 1;
}

/* Form Section */
.contact-form {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  padding: 1.5rem;
  min-height: 65vh;
  /* Removed max-height so the form can grow */
  /* max-height: 80vh; */
  border-radius: 10px;
  background-image: linear-gradient(to right, #FFFFFF1F, #9999991F);
  border: 2px solid white;
  position: relative;
  /* White border line around the form */
}

.contact-info {
  flex-grow: 1;
  padding: 1rem;
  box-shadow: #808080FF 1px 1px 28px;
  background-image: url(/public/assets/contactBackground.svg);
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

/* Increase width for large screens */
@media only screen and (min-width: 900px) {
  .contact-info {
    width: 22rem;
    /* Increase the width */
  }

  .contact-form {
    width: 60rem;
    /* Increase the width to accommodate the Contact Info box */
  }
}

.contact-fields {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-left: 2rem;
  width: calc(100% - 4rem);
}

/* Contact Info Header */
.contact-info-header {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
}

.contact-info-header label {
  font-size: 1.6rem;
  font-weight: bold;
}

.contact-info-header span {
  font-size: 0.8rem;
  color: #C9C9C9;
}

/* Contact Info List */
.contact-info-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  padding: 0.5rem;
}

.contact-info-item {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.contact-info-icon {
  width: 1.2rem;
}

.contact-info-item span {
  font-size: 1.2rem;
  opacity: 90%;
  font-weight: normal;
}

/* Social Media Section */
.social-media {
  display: flex;
  gap: 0.7rem;
  justify-content: center;
}

.social-media-icon {
  background: #885EFE;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-icon {
  width: 60%;
  height: auto;
  object-fit: contain;
}

/* Contact Fields */
.contact-fields {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-left: 2rem;
  width: calc(100% - 4rem);
}

.contact-field-row {
  display: flex;
  gap: 1rem;
}

.contact-field {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.1rem;
  width: 100%;
}

.contact-field label {
  font-size: 0.9rem;
  color: white;
  font-weight: bold;
}

.contact-field input,
.contact-field textarea {
  font-size: 1rem;
  color: white;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  padding: 0.6rem;
  outline: none;
  transition: border-color 0.3s ease, background-color 0.3s ease;
  font-family: Calibri, 'Trebuchet MS', sans-serif;
}

.contact-field input {
  height: 1rem;
}

.contact-field textarea {
  min-height: 150px;
  max-height: 350px;
  resize: none;
}

.contact-field input::placeholder,
.contact-field textarea::placeholder {
  color: rgba(255, 255, 255, 0.6);
  font-family: Calibri, 'Trebuchet MS', sans-serif;
}

.contact-field input:focus,
.contact-field textarea:focus {
  border-color: #885EFE;
  background: rgba(255, 255, 255, 0.15);
}

/* Submit Button */
.contact-submit {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2rem;
  position: sticky;
  bottom: 0;
  background-color: transparent;
  padding: 0.5rem 0;
}

.contact-submit button {
  background: #885EFE;
  border: none;
  padding: 0.6rem 1rem;
  color: white;
  font-weight: bold;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: Calibri, 'Trebuchet MS', sans-serif;
}

/* Desktop button override: remove absolute positioning */
.contact-submit-v2 {
  /* You could position it differently if you like, e.g.: */
  /* margin-top: 1rem; */
  /* align-self: flex-end; */
}

.contact-submit button:hover {
  background: #7040E0;
}

/* Responsive Design */
@media only screen and (max-width: 1200px) {
  .contact-hrLine {
    width: 25rem;
  }
}

@media only screen and (max-width: 992px) {
  .contact-hrLine {
    width: 20rem;
  }
}

@media only screen and (max-width: 900px) {
  .contact-form {
    width: 80%;
    height: auto;
    flex-direction: column;
    gap: 1.5rem;
    /* max-height: none; */
    overflow-y: hidden;
    padding: 1.5rem;
    position: relative;
  }

  .contact-info {
    width: 100%;
    height: auto;
    box-shadow: none;
    padding: 1rem;
    margin-bottom: 1rem;
  }

  .contact-info-list {
    gap: 1rem;
  }

  .contact-field-row {
    flex-direction: column;
    gap: 1rem;
  }

  .contact-submit {
    align-items: flex-end;
  }
}

@media only screen and (max-width: 768px) {
  .contact-header {
    flex-direction: column;
    gap: 0.5rem;
  }

  .contact-title-wrapper {
    flex-direction: column;
  }

  .contact-title {
    font-size: 2rem;
    margin: 0.5rem 0;
  }

  .contact-hrLine {
    width: 50%;
    max-width: 15rem;
  }

  .contact-form {
    width: 80%;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
    max-height: none;
    height: auto;
    overflow-y: hidden;
  }

  .contact-info {
    width: 100%;
    height: auto;
    padding: 1rem;
    box-shadow: none;
    background-size: cover;
  }

  .contact-fields {
    padding-left: 0;
    width: 100%;
  }

  .contact-field-row {
    flex-direction: column;
    gap: 1rem;
  }

  .contact-field label {
    font-size: 0.9rem;
  }

  .contact-field input,
  .contact-field textarea {
    font-size: 1rem;
    padding: 0.5rem;
  }

  .social-media {
    justify-content: center;
  }

  .contact-submit {
    justify-content: center;
  }

  .contact-submit button {
    width: 100%;
    font-size: 1rem;
  }
}

@media only screen and (max-width: 480px) {
  .contact-title {
    font-size: 2rem;
    margin-top: 8.5rem;
  }

  .contact-info-header {
    margin-bottom: 2rem;
    align-items: center;
  }

  .contact-hrLine {
    display: none;
  }

  .contact-form {
    padding: 0.3rem;
  }

  .contact-fields {
    padding: 0;
  }

  .contact-field input,
  .contact-field textarea {
    padding: 0.4rem;
  }

  .contact-submit button {
    font-size: 0.9rem;
  }

  .contact-info {
    background-size: cover;
    width: 96%;
    padding: 0.5rem;
  }
}